
























































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
import Pagination from "@/components/paginaiton.vue";
import {
  qianyueList,
  shenhe,
  qianyueJilu,
  getDiseases,
} from "@/request/doctor";
@Component({
  components: {
    MainHeader,
    NavLeft,
    Pagination,
  },
})
export default class Name extends Vue {
  private filter: any = {
    慢病: "",
    状态: "",
    search: "",
    institution_id: "",
    current_page: 1,
    page_count: 9,
    total_page: 0,
    totalCount: 0,
    sort_value: 1,
    中止原因: "",
    审核结果: "",
    不通过原因: "",
    签约时长: "",
  };
  private diseases: any = [];
  private cData: any; // 当前要操作的患者信息
  private ifJilu: boolean = false;
  private ifZhongzhi: boolean = false;
  private ifShenhe: boolean = false;
  private ifQianyue: boolean = false;
  private list: any = [];
  private jiluList: any = [];
  private dialogText = "";
  private get user() {
    return this.$store.state.user;
  }
  private goRouter(items: any) {
    if (this.user["角色"] === "普通医生" || this.user["角色"] === "高级医生") {
      this.$router.push({
        path: "/main/workstation/patient-edit-message",
        query: {
          patient_id: items.patient_id,
        },
      });
    } else {
      this.$router.push({
        path: "/main/workstation/patient-edit-message",
        query: {
          patient_id: items.patient_id,
          if_view: "true",
        },
      });
    }
  }
  private init() {
    this.filter["中止原因"] = "";
    this.filter["不通过原因"] = "";
    this.filter["签约时长"] = "";
    this.filter["审核结果"] = "";
  }
  private getList() {
    const params = {
      current_page: this.filter.current_page,
      sort_value: this.filter.sort_value,
      disease_id: this.filter["慢病"],
      签约状态: this.filter["状态"],
      search: this.filter.search,
      page_count: this.filter.page_count,
    };
    qianyueList(this, params).then((res: any) => {
      this.list = res.patients;
      this.filter.total_page = res.total_page;
      this.filter.totalCount = res.total_count;
    });
  }
  private getJilu(item: any) {
    const params = {
      patient_id: item.patient_id,
      source: "pc医生",
    };
    qianyueJilu(this, params).then((res: any) => {
      this.jiluList = res;
      this.ifJilu = true;
    });
  }
  /**
   * @description 搜索
   */
  private search() {
    this.filter.current_page = 1;
    this.getList();
  }
  /**
   * @description 处理页码变化
   */
  private handleCurrentPageChange(val: any) {
    this.filter.current_page = val;
    this.getList();
  }
  private goShenhe() {
    if (!this.filter["审核结果"]) {
      this.$message.error("审核结果必须选择！");
      return;
    }
    if (this.filter["审核结果"] === "通过") {
      this.ifShenhe = false;
      this.ifQianyue = true;
    } else {
      this.goAction("不通过");
    }
  }
  private goAction(action: any) {
    const params: any = {
      contract_id: this.cData.contract_id,
      patient_id: this.cData.patient_id,
      action,
    };
    if (action === "不通过") {
      if (!this.filter["不通过原因"]) {
        this.$message.error("不通过原因必填！");
        return;
      }
      params["原因"] = this.filter["不通过原因"];
    } else if (action === "通过") {
      if (!this.filter["签约时长"]) {
        this.$message.error("签约时长必须选择！");
        return;
      }
      params["签约时长"] = this.filter["签约时长"];
    } else if (action === "中止") {
      if (!this.filter["中止原因"]) {
        this.$message.error("中止原因必填！");
        return;
      }
      params["原因"] = this.filter["中止原因"];
    }
    shenhe(this, params).then((res: any) => {
      this.ifShenhe = false;
      this.ifQianyue = false;
      this.ifZhongzhi = false;
      this.getList();
    });
  }
  private getDiseases() {
    getDiseases(this).then((res: any) => {
      this.diseases = res;
    });
  }
  mounted() {
    this.getDiseases();
    this.getList();
  }
}
